import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import Button from '../../../components/Button/Button'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../../../components/Layouts/layout'
import SEO from '../../../components/seo'
import ComplaintsComplianceNav from '../../../components/SubPageNav/ComplaintsComlianceNav'
import SubPageBottomNav from '../../../components/SubPageBottomNav/SubPageBottomNav'

const BuilderLATPage = () => {
  const intl = useIntl()
  return (
    <Layout
      view='builder'
      breadcrumb={{
        text: <FormattedMessage id='builderNav.dashboard' />,
        path: '/builder-vendor/dashboard/'
      }}
      header={<FormattedMessage id='builderNav.complaints2' />}
    >
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'builderNav.complaints' })}
      />
      <Container>
        <Row>
          <Col lg={3} md={3}>
            <ComplaintsComplianceNav activePath='/builder-vendor/complaints-compliance-enforcement/licence-appeal-tribunal/' />
          </Col>

          <Col>
            <Row>
              <Col xl={12}>
                <p style={{ marginTop: `0` }}>
                  <FormattedMessage id='builderComplaints2.contentA' />
                </p>

                <p>
                  <FormattedMessage id='builderComplaints2.contentB' />
                </p>

                <p>
                  <FormattedMessage id='builderComplaints2.contentC' />
                </p>
              </Col>
            </Row>

            <Row>
              <Col>
                <p>
                  <FormattedMessage id='builderComplaints2.contentD' />
                </p>
                <Button
                  linkExternal
                  id='noticeLink'
                  linkPath='https://tribunalsontario.ca/documents/lat/02026E_Notice-of-Appeal_Registration-Certificate-Licence-Claim-Denial-Order.pdf'
                  text={<FormattedMessage id='builderComplaints2.contentE' />}
                />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <p>
                  <FormattedMessage id='builderComplaints2.contentF' />
                </p>

                <p>
                  <FormattedMessage id='builderComplaints2.contentG' />
                </p>
              </Col>

              <Col md={6}>
                <h3>
                  <FormattedMessage id='builderComplaints2.headerA' />
                </h3>
                <p>
                  <a href='mailto:LATregistrar@ontario.ca'>
                    LATregistrar@ontario.ca
                  </a>
                  <br />
                  <br />
                  <FormattedMessage id='builderComplaints2.headerA' />
                  <br />
                  <FormattedMessage id='builderComplaints2.address01A' />
                  <br />
                  <FormattedMessage id='builderComplaints2.address01B' />
                </p>
              </Col>

              <Col md={6}>
                <h3>
                  <FormattedMessage id='builderComplaints2.headerB' />
                </h3>
                <p>
                  <a href='mailto:Legal@hcraontario.ca'>Legal@hcraontario.ca</a>
                  <br />
                  <br />
                  Registrar
                  <br />
                  <FormattedMessage id='builderComplaints2.headerB' />
                  <br />
                  <FormattedMessage id='builderComplaints2.address02A' />
                  <br />
                  <FormattedMessage id='builderComplaints2.address02B' />
                </p>
              </Col>

              <Col xl={12}>
                <p>
                  <FormattedMessage id='builderComplaints2.contentH' />
                </p>
                <Button
                  linkExternal
                  id='tribunalsLink'
                  linkPath='https://tribunalsontario.ca/lat/general-service/appeals-applications/'
                  text={<FormattedMessage id='builderComplaints2.contentI' />}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            <SubPageBottomNav
              prevLink={{
                text: <FormattedMessage id='adminPenalties.header' />,
                path:
                  '/builder-vendor/complaints-compliance-enforcement/administrative-penalty/'
              }}
              nextLink={{
                text: <FormattedMessage id='builderNav.complaints3' />,
                path:
                  '/builder-vendor/complaints-compliance-enforcement/compliance-enforcement/'
              }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default BuilderLATPage
